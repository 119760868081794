import React, { FunctionComponent, useEffect, useState } from "react"
import styled from "styled-components";
import fluidlogo from "../../images/fluidlogo.gif";
import arrow from "../../images/arrow.png";


const StyledArrow = styled.div`
  position: relative;
  font-size: 12px;
  line-height: 1;
  font-family: proxima-nova, sans-serif;
  position: absolute;
  width: 275%;
  height: 100%;
  color: #dcdbe0;
  text-shadow: 1px 1px 1px rgba(0,0,0,.8);

  @media screen and (max-width: 650px) {
    width: 175%;
  }

  pointer-events: none;
`;

const ArrowText = styled.div`
  display: inline-block;
  z-index: 10;
`;

const StyledArrowImage = styled.img.attrs(() =>({
  src: arrow
}))`
  position: absolute;
  z-index: 1;
  width: 30px;
  top: 5%;
  left: 31%;

  @media screen and (max-width: 650px) {
    left: 35%;
  }
`
const TwoMinBackground = styled.img.attrs(() =>({
  src: fluidlogo
}))`
  margin-bottom: -30px;
  width: 200px;
  left: 30%;
  transform: scale(1);
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 650px) {

  }
`


const StyledPlay = styled.div`
  position: relative;
  &:hover ${TwoMinBackground}{
    transform: scale(1.1);
  };
`;

const StyledAlbumIn1 = styled.div`
  position: relative;
  z-index: 1;
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: -40px;
  color: #010a32;
  text-shadow: 3px 1px 0px rgba(255,249,255,.65);

  @media screen and (max-width: 650px) { 
    font-size: 30px;
  }
`;
const StyledAlbumIn2 = styled.div`
  font-size: 37px;
  color: #010a32;
  text-shadow: 3px 1px 0px rgba(255,249,255,.65);

  @media screen and (max-width: 650px) { 
    font-size: 32px;
    width: 200px;
    margin-top: 14px;
  }
`;

const StyledDisclaimer = styled.div`
  font-family: proxima-nova, sans-serif;
  font-weight: bold;
  font-size: 10px;
  color: #dcdbe0;
  text-shadow: none;
  background: none;
  padding: 7.5px 0;
`;



interface Props {
  breakpoint?: number;
}

export const PreviewPlayerLabel: FunctionComponent<Props> = ({ breakpoint}) => {


  return (
    <>
      <StyledArrow>
          <ArrowText>Complaining sound {breakpoint && <br/>} will start </ArrowText>
        <StyledArrowImage />
      </StyledArrow>
      <StyledPlay>
        <TwoMinBackground />
      </StyledPlay>
    </>
    ) 
  }
