import React, { FunctionComponent, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import border from "../images/border2.svg"

interface Props {
  title: string;
}

interface StyledButtonProps {
  titleColor?: string;
  titleSize?: number;
  top: number;
  left: number;
  rotate?: number;
  customContainer?: boolean;
}

interface StyledPopupProps {
  popupTop: number;
  popupLeft: number;
  noBackground?: boolean;
}

type CombinedProps = Props & StyledButtonProps & StyledPopupProps;


export const Link = styled.button<StyledButtonProps>`
  @import url("https://use.typekit.net/kez6crt.css");
  font-family: flood-std, georgia;
  font-weight: 400;
  font-style: normal;
  text-shadow: -2px 3px 1px rgba(28,39,47,0.76);
  position: absolute;
  border: none;
  cursor: pointer;
  color: ${({titleColor}) => titleColor};
  top: ${({ top }) => top}%;
  left: ${({ left }) => left}%;
  transform: translate(-50%, -50%);
  font-size: ${({ titleSize }) => titleSize}px;
  line-height: 0.8;
  background: none;

  transition: all 0.1s ease-in-out;
  transform: scale(1);
  &:hover {
    text-shadow: -2px 3px 1px rgba(28,39,47,0.9);
    transform: rotate(${({ rotate }) => rotate - 1}deg) scale(1.02);
  }

  transform: rotate(${({ rotate }) => rotate}deg);

    @media screen and (max-width: 500px) {
      font-size: ${({ titleSize }) => titleSize / 1.5}px;
    }
`;

const LabelArrow = styled.div`
  display: none;
  position: absolute;
  top: -10px;
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #010a32CC;
`;


export const PopupContainer = styled.div`
  width: 100vw;
  height: 100vh;
`;

const Popup = styled.div`
  z-index: 10;
  position: absolute;
  line-height: 1.5;
  font-size: 14px;
  max-width: 600px;
  background: ${({ noBackground }) => noBackground ? "none" : "#f3f5f0E6"};
  padding: 30px;
  border-width: 20px;
  border-style: solid;
  border-image: url(${border}) 200 80 repeat;

  top: ${({ popupTop }) => popupTop}%;
  left: ${({ popupLeft }) => popupLeft}%;

  a {
    font-size: 20px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 500px) {
    margin-left: auto;
    margin-right: auto;
    left: 5%;
    right: 5%;
    top: ${({ popupTop }) => popupTop - 5}%;
  }
`;

export const Info: FunctionComponent<CombinedProps> = ({ title, children, titleSize = 50, titleColor = "#149100", top, left, rotate = 0, popupTop, popupLeft, noBackground = false, customContainer = true}) => {

  const [showAbout, setShowAbout] = useState(false);

  return (
    <>
      
      <Link onClick={() => setShowAbout(!showAbout)} titleSize={titleSize} top={top} left={left} titleColor={titleColor} rotate={rotate}>
        <div dangerouslySetInnerHTML={{ __html: title }} />
        <LabelArrow />
      </Link>
      {showAbout && 
        <PopupContainer onClick={() => setShowAbout(false)}>
          {customContainer ?
            <Popup popupTop={popupTop} popupLeft={popupLeft} noBackground={noBackground}>
              {children}
            </Popup>
            : 
            <>
              {children}
            </>
          }
          
        </PopupContainer>
      }
  </>
  )
}


