import React, { FunctionComponent } from "react"
import styled, { css } from "styled-components"
import Timer from 'react-compound-timer'

const CounterText = styled.div`
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translateX(-50%); 
  color: #f3f5f0;
  font-size: 20px;
  width: 100%;
  font-weight: bold;
  text-align: center;
  padding-top: 10px;  
`;

interface Props {
  songName: (name: any) => void;
  onFinish: () => void;
}

export const TimerComponent: FunctionComponent<Props> = ({songName, onFinish}) => {

  
  const toMilliseconds = (min, sec, mill) => {
    const minToMill = Math.floor(min * 60000);
    const secToMill = sec * 1000;
    return minToMill + secToMill + mill;
  }

  toMilliseconds(0,6,300);
  
  const convertTime = (min, sec, mill) => {
    const songDuration = toMilliseconds(1, 19, 0);
    const minAndSec = toMilliseconds(min, sec, mill);
    return songDuration - minAndSec;
  }

  

    return (
      <Timer 
      initialTime={79000}
      direction="backward"
      checkpoints={[
        {
          time: 500,
          callback: () => onFinish(),
        },
        {
          time: convertTime(1, 16, 500),
          callback: () => {
            songName("");
          },
        },
        {
          time: convertTime(1, 11, 500),
          callback: () => {
            songName("That occupied me for a long while");
          },
        },
        {
          time: convertTime(1, 11, 0),
          callback: () => {
            songName("");
          },
        },
        {
          time: convertTime(1, 9, 0),
          callback: () => {
            songName("Ever");
          },
        },
        {
          time: convertTime(1, 8, 0),
          callback: () => {
            songName("Nothing");
          },
        },
        {
          time: convertTime(1, 7, 0),
          callback: () => {
            songName("Ever");
          },
        },
        {
          time: convertTime(1, 6, 0),
          callback: () => {
            songName("Never");
          },
        },
        {
          time: convertTime(0, 54, 0),
          callback: () => {
            songName("Now and then ¯\_(ツ)_/¯");
          },
        },
        {
          time: convertTime(0, 50, 500),
          callback: () => {
            songName("");
          },
        },
        {
          time: convertTime(0, 48, 500),
          callback: () => {
            songName("Simple interactions");
          },
        },
        {
          time: convertTime(0, 46, 500),
          callback: () => {
            songName("Satisfactions");
          },
        },
        {
          time: convertTime(0, 43, 0),
          callback: () => {
            songName("Hard up lately");
          },
        },
        {
          time: convertTime(0, 37, 500),
          callback: () => {
            songName("Sheer force of habit");
          },
        },
        {
          time: convertTime(0, 35, 500),
          callback: () => {
            songName("");
          },
        },
        {
          time: convertTime(0, 33, 0),
          callback: () => {
            songName("Fabian Olsen's new baked bread");
          },
        },
        {
          time: convertTime(0, 29, 0),
          callback: () => {
            songName("");
          },
        },
        {
          time: convertTime(0, 25, 500),
          callback: () => {
            songName("Protect the nest");
          },
        },
        {
          time: convertTime(0, 17, 100),
          callback: () => {
            songName("You should help others");
          },
        },
        {
          time: convertTime(0, 11, 900),
          callback: () => {
            songName("Lying awake");
          },
        },
        {
          time: convertTime(0,6,0),
          callback: () => {
            songName("");
          },
        },
        {
          time: convertTime(0,5,0),
          callback: () => {
            songName("Singular city");
          },
        },
        {
          time: convertTime(0,1,950),
          callback: () => {
            songName("Kristiania");
          },
        },
      ]}>
      
        {({ start, resume, pause, stop, reset, getTimerState, getTime }) => (
          <CounterText>
            <Timer.Minutes />:
            <Timer.Seconds /> 
          </CounterText>
      )}
    </Timer>
    )
  }