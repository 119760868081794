import React, {FunctionComponent, useState} from "react"
import styled from "styled-components"


const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  font-family: papyrus;
  background: #ece5c2;
  border: 2px ridge white;
  padding: 10px;

  input, textarea {
    background: none;
    border: none;
    outline: none;
  }

  input[type="reset"]:focus{
    outline: none;   
  }

  & > * {
    margin-bottom: 10px;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  & > button {
    align-self: center; 
    width: 50%;
    border: 2px ridge white;
    color: #646048;
    font-weight: bold;
  }
`;

const SucessMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 275px;
  color: #188e36;
  font-family: papyrus;
  font-size: 30px;
  font-weight: bold;
`;

const ErrorMessage = styled.div`
  width: 250px;
  height: 275px;
  color: red;
  font-weight: bold;
  margin-top: 50px;
`;


function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const Contact: FunctionComponent = () => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [formSuccessMessage, setFormSuccessMessage] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState(false);

  // encodes the captured form data in the format that Netlify's backend requires
  // example: form-name=contact&firstName=a&lastName=b&jobTitle=a&companyName=a&phone=3&email=me%40you.com&message=tes
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  };

  const handleChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formState,
      }),
    })
      .then(() => setFormSuccessMessage(true))
      .catch((error) => setFormErrorMessage(true));
  };

  if (!formSuccessMessage) {
    return (
    

      <StyledForm method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" onSubmit={handleSubmit}>
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <input
          id="name"
          name="name"
          type="text"
          placeholder="Name"
          required
          onChange={handleChange}
          value={formState.name}
        />

        <input
          name="email"
          type="email"
          placeholder="Email"
          onChange={handleChange}
          value={formState.email}
        />
        <textarea
          id="message"
          name="message"
          rows={5}
          placeholder="Message"
          required
          onChange={handleChange}
          value={formState.message}
        ></textarea>
        <button type="submit">Send 🕊️</button>
      </StyledForm>
    )
  }

  if (formSuccessMessage) {
    return (
      <SucessMessage> Thank you ❦</SucessMessage>
    )
  }
  if (formErrorMessage) {
    return (
      <ErrorMessage> Something went wrong and the message was <i>not</i> sent. <br/> Send us a message on <br/><a href="https://www.instagram.com/akersborg/" target="_blank"> instagram </a>  </ErrorMessage>
    )
  }


};