import React, { FunctionComponent, useEffect } from "react"
import styled, { css } from "styled-components"
import { useMediaQuery } from "react-responsive"
import { useResizeDetector } from 'react-resize-detector';
import {mobileVendor, isChrome, isFirefox, isSafari} from "react-device-detect";

const widthBreakpoint = "550px";

const getFontSize = (windowWidthIsSmall: boolean, aspectRatio: number, fontAdjust: number = 1) => {

  const fontMedium = 20 / fontAdjust;
  const fontSmall = 15 / fontAdjust;
  const fontLarge = 29 / fontAdjust;

  if(aspectRatio < 1.5 && windowWidthIsSmall) return css`font-size: ${fontSmall}vh; word-break: break-word;`; 
  if(aspectRatio < 1.5 && !windowWidthIsSmall) return css`font-size: ${fontMedium}vw;`;
  if(aspectRatio > 1.5) return css`font-size: ${fontLarge}vh`;
}

const isIphoneAndBrowser = mobileVendor === "Apple" && (isChrome || isFirefox || isSafari);

const OuterContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledSongName = styled.div`
  position: absolute;
  left: 10%;
  top: 10%;
  z-index: 100;
  pointer-events: none;
  font-weight: bold;
  line-height: .8;
  color: #2508a3;
  text-align: center;
  max-width: 100%;
`;

interface InnerContainerProps {
  windowWidthIsSmall: boolean;
  aspectRatio: boolean;
  isIphoneAndBrowser: boolean;
}

const InnerContainer = styled.div`
  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  
  width: 80vw;
  ${props => props.isIphoneAndBrowser ? css`height: calc(80vh - 80px);` : css`height: 80vh;`};

  ${props => getFontSize(props.windowWidthIsSmall, props.aspectRatio, 1.25)};

  @media screen and (max-width: ${widthBreakpoint}) {
    line-height: 1;
  }
`;

interface Props {
  songName: string;
}

export const AnimatedSongName: FunctionComponent<Props> = ({songName}) => {

  const windowWidthIsSmall = useMediaQuery({query: `(max-width: ${widthBreakpoint})`});
  const { width, height, ref } = useResizeDetector();
  const aspectRatio = width / height;

  return (
    <OuterContainer ref={ref}>
      <StyledSongName>
        <InnerContainer windowWidthIsSmall={windowWidthIsSmall} aspectRatio={aspectRatio} isIphoneAndBrowser={isIphoneAndBrowser}>
          {songName}
        </InnerContainer>
      </StyledSongName>
    </OuterContainer>
  )
  }

