import React, { useEffect, useCallback } from "react";
import styled, { css } from "styled-components"
import soundFile from "../../audio/pitreflections.mp3"

export const StyledStopButton = styled.button`
  position: fixed;
  top: 2%;
  left: 1%;
  z-index: 200;
  span {
    display: inline-block;
    font-size: 75px;
    transform: rotate(-45deg);
  }
  background: none;
  border: none;
  color: #f3f5f0;
  opacity: 0.85;
  font-weight: bold;
  cursor: pointer;
`;

const StyledTemp = styled.h1`
  z-index: 9999;
  position: absolute;
`;

let audioFile, audioContext, source, analyser;


class AudioPlayer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    audioFile = new Audio();
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    audioContext = new AudioContext();
    source = audioContext.createMediaElementSource(audioFile);
    analyser = audioContext.createAnalyser();
    audioFile.src = soundFile;
    analyser.fftSize = 64;
    source.connect(audioContext.destination);
    source.connect(analyser);
    audioFile.play();
    this.setState({
      audioData: analyser
    })
  }

  
  startAndStop = (isPlaying) => {
    if (!isPlaying && audioFile) {
      audioFile.play();
      this.setState({
        audioData: analyser
      })
      this.props.audioContext(audioContext.state)
    }

    if(!audioFile) {
      console.log("Can't load audio. Please refresh")
    }

    const onStop = () => {
      audioFile.src = "";
      this.props.audioContext(audioContext.state);
      this.props.playerHasBeenStopped(true);
      document.location.href="/";
    }
      
    if(isPlaying && audioContext.state === "running") {

      audioFile.pause();
      audioFile.currentTime = 0;

      setTimeout(() => {
        audioContext.close().then(function() {
          onStop();
        });
      }, 100)
    }

  }


  getFrequencyData = (styleAdjuster) => {
    const bufferLength = this.state.audioData.frequencyBinCount;
    const amplitudeArray = new Uint8Array(bufferLength);
    this.state.audioData.getByteFrequencyData(amplitudeArray)
    styleAdjuster(amplitudeArray)
  }

  getAmplitude = (amp) => {
    this.props.sendAmplitude(amp);
  }


  render(){

    return (
      <div>
        <VisualDemo
          timerIsDone={this.props.timerIsDone}

          startAndStop={this.startAndStop}
          getFrequencyData={this.getFrequencyData}
          audioData={this.state.audioData}
          getAmplitude={this.getAmplitude}
          startPlayer={this.startPlayer}
        />
      </div>
    );
  }
}

export default AudioPlayer;


export const VisualDemo = function(props) {

  useEffect(() => {
    handleStartButtonClick(false);
  }, [])

  function adjustFreqBandStyle(newAmplitudeData){
    props.getAmplitude(newAmplitudeData[20]);
  };

  function runSpectrum(){
    props.getFrequencyData(adjustFreqBandStyle)
    requestAnimationFrame(runSpectrum)
  }

  function handleStartButtonClick(isPlaying){
    props.startAndStop(isPlaying)
    requestAnimationFrame(runSpectrum)
  }

  if (props.startPlayer) {
    handleStartButtonClick(false);
  }

  const escFunction = useCallback((event) => {
    if(event.keyCode === 27) {
      handleStartButtonClick(true);
    }
  }, []);

  useEffect(() => {
    if (props.timerIsDone) {
      handleStartButtonClick(true);
    }
  }, [props.timerIsDone])


  

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <StyledStopButton onClick={() => { handleStartButtonClick(!!props.audioData) }}><span>+</span></StyledStopButton>
  );

}