import React from "react";
import three from "../images/three.jpg"
import gate from "../images/gate.jpg"
import restaurant from "../images/restaurant.jpg"
import stone from "../images/stone.jpg"
import rehersal from "../images/rehersal.jpg"
import shessuch from "../images/shessuch.jpg"
import styled from "styled-components"
import BackgroundSlider from "react-background-slider"
import { useMediaQuery } from "react-responsive"
import {breakingPoint} from "../pages/frontPage"


const Background = () => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${breakingPoint})` });
  return ( 
    <BackgroundSlider images={[rehersal, gate, restaurant, three, shessuch, stone]} duration={15} transition={2} />
  )
}

export default Background;


