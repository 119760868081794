import React, { FunctionComponent, useEffect, useState } from "react"
import Background from "../components/background"
import { Info, Link } from "../components/info"
import "../components/layout.css"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
import AudioPlayer from "../components/twoMinPlayer/audioPlayer"
import { TimerComponent } from "../components/twoMinPlayer/soundTimer"
import { AnimatedSongName } from "../components/twoMinPlayer/animatedSongName"
import { useDispatch, useSelector } from "react-redux"
import { playerHasFinished, startPlayer } from "../store/actions"
import { RootState } from "../store/rootReducer"
import { PreviewPlayerLabel } from "../components/previews/previewPlayerLabel"
import { Contact } from "../components/contactForm"
import spotifyLogo from "../images/spotify.png"


export const breakingPoint = "750px";


const getOpacity = (twoMinPlayerIsOn) => {
  return twoMinPlayerIsOn ? 0.5 : 0;
}

const FullscreenOnClick = styled.div`
  width 100vw;
  height: 100vh;
`;


const StyledAnimatedBackground = styled.div`
  ${props => `background-color: rgb(${props.amp}, 24, 24, ${getOpacity(props.twoMinPlayerIsOn)})`};
  height: 100vh;
  width: 100vw;
`;

const Quote = styled.i`
  text-align: center;
  font-size: 70px;
  line-height: 1;
  font-weight: bold;
  color: black;
  text-shadow: 0 0 0.6rem #fff, 0 0 1.5rem #fff,
  -0.2rem 0.1rem 1rem #fff, 0.2rem 0.1rem 1rem #fff,
  0 -0.5rem 2rem #fff, 0 0.5rem 3rem #ff2483;

  @media screen and (max-width: 920px) {
    font-size: 40px;
  }
`;

const JavlaLivBackground = styled.div`
padding: 40px;
background: repeating-radial-gradient(
  circle,
  red 0px,
  red 20px,
  #fff 20px,
  #fff 40px
  );
  
  text-shadow: -1px 1px 1px rgba(28,39,47,0.9);
  @media screen and (max-width: 920px) {
    padding: 20px;
  }
`;

const SpotifyLogoLink = styled.img.attrs(() =>({
  src: spotifyLogo
}))`
  position: absolute;
  width: 75px;
  height: 75px;
  top: 26%;
  right: 21%;
  animation-direction: normal;
  transition: all 10s linear;
  &:hover {
    transform: rotate(10128deg);
  };

  @media screen and (max-width: 650px) {
    width: 50px;
    height: 50px;
    top: 56%;
    left: 21%;
  }
`


const FrontPage: FunctionComponent = () => {

  const dispatch = useDispatch();

  let breakpoint = useMediaQuery({ query: `(max-width: ${breakingPoint})` }) 

  useEffect(() => {
    if (typeof window !== `undefined` && window.innerWidth <= 750 ) {
      breakpoint = false;
    }
  }, [])

  const [twoMinPlayerIsOn, setTwoMinPlayerIsOn] = useState(false);
  const [audioContext, setAudioContext] = useState(undefined);
  const [amp, setAmp] = useState(0);
  const [songName, setSongName] = useState("");

  const playerIsDone = useSelector((state: RootState) => state.playerHasFinished);
  const [timerIsDone, setTimerIsDone] = useState(false);

  useEffect(() => {
    if (playerIsDone.playerHasFinished) {
      setTimerIsDone(true);
    }
  }, [playerIsDone.playerHasFinished]);

  const [showPlayer, setShowPlayer] = useState(false);
  const [hideElements, setHideElements] = useState(false);

  useEffect(() => {
    if (breakpoint && showPlayer) {
      setHideElements(true);
    }
  }, [showPlayer, breakpoint])


  const onPlayerStart = () => {
    setTwoMinPlayerIsOn(true);
    setSongName("");
    dispatch(startPlayer());
  }


  if (typeof window === `undefined`) {
    return(<></>);
  }


  return (

      <div className="layout">
        {showPlayer && <FullscreenOnClick onClick={() => {
            setShowPlayer(false);
            setHideElements(false);
          }} />
        }
        <StyledAnimatedBackground amp={amp} twoMinPlayerIsOn={twoMinPlayerIsOn}>
          { twoMinPlayerIsOn &&
            <>
              <AnimatedSongName songName={songName}/>
              <AudioPlayer 
                timerIsDone={timerIsDone}
                sendAmplitude={(ampFrom) => setAmp(ampFrom)}
                playerHasBeenStopped={(stop) => setTwoMinPlayerIsOn(!stop)}
                audioContext={(audioContext) => setAudioContext(audioContext)}/>
                {amp > 1 &&  <TimerComponent songName={(name) => setSongName(name)} onFinish={() => {dispatch(playerHasFinished())}} /> }
            </>
          }
          {(!twoMinPlayerIsOn || audioContext === "stopped") &&
            <>
            {!hideElements && !showPlayer &&
              <Link top={breakpoint ? 10 : 7} left={breakpoint ? 10 : 40} titleColor="#e2cc7f" titleSize={50} onClick={onPlayerStart}>
                <PreviewPlayerLabel breakpoint={breakpoint}/>
              </Link>
            }  

              {!hideElements &&
                <>
                  <Info title="Et jävla liv vi lever" titleColor="#ffffff" titleSize={40} top={breakpoint ? 45 : 40} left={25} popupTop={30} popupLeft={20} rotate={10} noBackground>
                    <JavlaLivBackground>

                    <a href="https://open.spotify.com/album/0Cb1sl3Q60YjLWINJcCOHr?si=HqnvJhfnTxGFRPT32hJD-w" target="_blank"> Listen on spotify </a> 
                    <br/>
                    <br/>
                    <p><Quote>Längtan efter att leva på riktigt</Quote></p>
                    <p>Read the <a href="/javlaliv">lyrics</a></p>
                    </JavlaLivBackground>
                  </Info>
                  <Info title="About" titleColor="#a01818" titleSize={46} top={breakpoint ? 65 : 55} left={60} popupTop={45} popupLeft={50} rotate={-4}>
                    <p> Akersborg is a new avant-garde hardcore band from Oslo, bridging unexpected musical landscapes and genres with distorted guitars, screamo and catchy hooks. Akersborg is presenting their debut album Feelantropicoco.</p>
                    <p> “Feelantropicoco” is a made up word that sounds fun, easy going and whimsical, but is really full of contradictions and complexity. Feelantropicoco the album is an escape from reality into the imaginative, using reality as the main ingredient.</p>
                  </Info>
                  <Info title="Contact" titleColor="#a01818" titleSize={33} top={90} left={50} popupTop={50} popupLeft={40} rotate={2} customContainer>
                    <div onClick={e => e.stopPropagation()}>
                      <a href="https://www.instagram.com/akersborg/" target="_blank"> instagram.com/akersborg </a> <br/>
                      <Contact />
                    </div>
                  </Info>
                  <Info title="Credits" titleColor="#a01818" titleSize={32} top={breakpoint ? 75 : 65} left={breakpoint ? 8 : 16} popupTop={45} popupLeft={10} rotate={6}>
                    <p>Written by Dag von Krogh Bunkholt</p>
                    <p>Vocalists: Maciek Ofstad (Kvelertak, The Needs), Agnete Kjølsrud (Animal Alpha, Djerv), Uggi Mane (Beaten To Death, She Said Destroy), Benjamin Helstad (actor, Yoga Fire), Bendik Brænne (Bendik Brænne, The Needs), Nikolai Grasaasen (The September When, Malin Pettersen) and Dag von Krogh Bunkholt</p>
                    <p>Bass: Togga Fresh, Drums: Tobias Ørnes (Shining, Aming For Enrike, Ishan). Guitars and synths by Dag von Krogh Bunkholt</p>  
                    <p>Mixed by Andreas Kvinge Sandnes</p>  
                    <p>Mastered by Alex DeYoung</p>  
                </Info>
                <a href="https://open.spotify.com/artist/2V1MHqYRUYdRIqIHfYI4R0?si=dxAzZBTtRj6xm1e-2oGzww" target="_blank">
                  <SpotifyLogoLink />
                </a>
                </>
              }
            </>
          }
          <Background/>
        </StyledAnimatedBackground>
      </div>

    ) 
  }

export default FrontPage;